function selectAllCheckbox() {
    const keycodes = { space: 32, enter: 13 }

    function selectAll(parent, checked) {
        const cbs = parent.querySelectorAll('input[type=checkbox]:not(.selectall-checkbox)');
        cbs.forEach((cb) => {
            cb.checked = checked;
        });
    }

    function handleClick(event) {
        const parent = event.target.parentNode;
        const checked = event.target.checked;
        selectAll(parent, checked);
    }

    function handleChildClick(event) {
        const parent = event.target.parentNode;
        const cbs = parent.querySelector('input[type=checkbox].selectall-checkbox');
        const checkedCheckboxes = parent.querySelectorAll('input[type="checkbox"]:not(.selectall-checkbox):checked');
        const totalCheckboxes = parent.querySelectorAll('input[type="checkbox"]:not(.selectall-checkbox)');

        if(checkedCheckboxes.length < totalCheckboxes.length) {
            cbs.checked = false;
        } else {
            cbs.checked = true;
        }
    }

    const checkboxes = [...document.getElementsByClassName("selectall-checkbox")];

    checkboxes.forEach(
    /** @type {HTMLCheckboxElement} */ (checkbox) => {
        checkbox.addEventListener("click", handleClick);

        checkbox.parentNode.querySelectorAll('input[type="checkbox"]:not(.selectall-checkbox)')
                   .forEach((c) => {
           c.addEventListener("click", handleChildClick);
        })

        const parent = checkbox.parentNode;
        const checked = checkbox.checked;
        let params = new URLSearchParams(document.location.search.substring(1));
        let form_submitted = params.get("form_submitted"); // is the form submitted

        if (!form_submitted) {
            selectAll(parent, checked);
        }
    })
}

document.addEventListener("turbolinks:load", function() {
    selectAllCheckbox()
})
