// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("chartkick")
// require("chart.js")
require("jquery-ui")

import 'bootstrap'
import "../stylesheets/surveys.scss"
import "../stylesheets/bulma-select2.scss"
import '../components/date_time_picker'
import '../src/checkbox'
// require("jquery-ui-multiselect-widget")

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip();
    $('#reports-filters select').select2();
    $('#reports-filters select').on("change", function(e) {
        let optionsNotSelected = $(this).find("option:not(:selected)").length
        let allSelected = optionsNotSelected == 0;
        let noneSelected = optionsNotSelected == $(this).find("option").length;
        let name = $(this).attr("name");

        if(allSelected || noneSelected) {
            $('<input>')
                .attr('type','hidden')
                .attr('id',name.replace(/\W/g, '') + '-hidden')
                .attr('name', name)
                .attr('value', 'all')
                .appendTo($(this).parent());
        } else {
            $('#'+name.replace(/\W/g, '')+'-hidden').remove();
        }
    });
})
const images = require.context('../images', true)
