import flatpickr from 'flatpickr'

let datepickers = []
function bindDateTimePickers() {
    [...document.querySelectorAll('[data-type="time"]')].forEach((time) => {
        datepickers.push(flatpickr(time, {
            enableTime: true,
            enableSeconds: true,
            noCalendar: true,
            altInput: true,
            altFormat: ' h:i:S K',
            dateFormat: 'H:i:S' // H:i
        }))
    });

    [...document.querySelectorAll('[data-type="datetime"]')].forEach((time) => {
        datepickers.push(flatpickr(time, {
            enableTime: true,
            altInput: true,
            altFormat: 'F J (D), Y - h:i:S K',
            dateFormat: 'Z' // Y-m-d H:i
        }))
    });

    [...document.querySelectorAll('[data-type="date"]')].forEach((date) => {
        datepickers.push(flatpickr(date, {
            altInput: true,
            allowInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d"
        }))
    });

    [...document.querySelectorAll('[data-type="daterange"]')].forEach((date) => {
        datepickers.push(flatpickr(date, {
            mode: 'range',
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d",
            allowInput: true,
            onChange: [function(selectedDates){
                const dateArr = selectedDates.map(date => this.formatDate(date, "Y-m-d"));
                $('[name="startdate"]').val(dateArr[0]);
                $('[name="enddate"]').val(dateArr[1]);
            }]
        }))
    });
}

document.addEventListener("turbolinks:load", function () {
    datepickers.forEach((date)=> {
        date.destroy()
    });
    datepickers = [];
    bindDateTimePickers()
})